import React, { useState, useEffect, useCallback } from 'react';
import logo from './bot.png';
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import './App.css';

const App = () => {
  const particlesInit = useCallback(async engine => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
    await console.log();
  }, []);

  const calculateTimeLeft = () => {
    const difference = +new Date(`2025-01-15`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Tage: Math.floor(difference / (1000 * 60 * 60 * 24)),
        Stunden: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Minuten: Math.floor((difference / 1000 / 60) % 60),
        Sekunden: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    // Special handling for Sekunden - always display them
    if (!timeLeft[interval] && interval !== 'Sekunden' && interval !== 'Minuten' && interval !== 'Stunden') {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{"   "}
      </span>
    );
  });

  return (
    <div className="App">
      <div className="particles-container">
        <Particles
          id="tsparticles"
          className="particles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fpsLimit: 60,
            particles: {
              color: {
                value: "#ffbd59",
              },
              links: {
                enable: true,
                distance: 120,
                color: "#ffbd59",
                opacity: 0.2,
                width: 1,
              },
              move: {
                enable: true,
                speed: 0.5,
                direction: "none",
                random: true,
                straight: false,
                outModes: {
                  default: "bounce",
                },
              },
              number: {
                value: 80,
                density: {
                  enable: true,
                  area: 800,
                },
              },
              opacity: {
                value: 0.2,
              },
              shape: {
                type: ["circle"],
              },
              size: {
                value: 4,
                random: true,
              },
              interactivity: {
                events: {
                  onHover: {
                    enable: false, // Disables hover interactions
                  },
                  onClick: {
                    enable: false, // Disables click interactions
                  },
                  resize: true,
                },
              },
              collisions: {
                enable: true,
                mode: "bounce"
              },
              bounce: {
                horizontal: {
                  value: 1
                },
                vertical: {
                  value: 1
                }
              },
              absorbers: [
                {
                  position: { x: 50, y: 50 },
                  size: {
                    value: 100,
                    density: 50
                  },
                  opacity: 1,
                  color: "#000000",
                  draggable: false
                }
              ]
            }
          }}
        />
      </div>
      <header className="App-header">
        <h2>  Coming soon ...</h2>
        <div className='logo-container'>
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <h3>Countdown</h3>
        <div className="timer">
          {timerComponents.length ? timerComponents : <span>Time's up!</span>}
        </div>
      </header>
    </div>
  );
};

export default App;
